<template>
  <div class="home">

    <div class="firstClass">
      <p id="title">Laporan Keuangan Gereja Toraja</p>
    </div>

  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  name: "Home",

  created() {
    this.$store.dispatch("getTransaksiUser");
    this.$store.dispatch("getTransaksiUser");
    this.$store.dispatch("fetchJenisTransaksi");
    this.$store.dispatch("fetchKelMa");
    this.$store.dispatch("getMataAnggaran");
    this.$store.dispatch("getTransaksiUser");
    // this.dataMasukan();
  }
};
</script>

<style scoped>
.home {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#title{
font-size: 45px;
color: rgb(65, 65, 64);
}
</style>