<template>
  <div class="ReportBulanan">
    <h2 class="mt-4 mb-2">Laporan Tahunan</h2>
    <div class="row justify-content-md-center mb-4 mt-4">
      <p class="mr-3">Periode:</p>
      <date-picker v-model="selectedYear" type="year" @change="this.getHead"></date-picker>
    </div>
    <div class="row justify-content-md-center">
      <div class="col col-lg-8 justify-content-md-center">
        <button class="btn" @click.prevent="tableHtmlToExcel('pemasukanTable')">
          <i class="fa fa-download"></i> Download
        </button>
        <div class="inRow">
          <lord-icon
            v-if="this.isLoading"
            src="https://cdn.lordicon.com/dpinvufc.json"
            trigger="loop"
            style="width:500px;height:500px">
          </lord-icon>
        </div>
        <table v-if="!this.isLoading" class=" table table-striped table-hover table-bordered" id="pemasukanTable">
          <thead>
            <tr>
              <th scope="col">NO.</th>
              <th scope="col">Kelompok Mata Anggaran</th>
              <th scope="col">Nilai</th>
              <th scope="col">Jumlah</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td id="head"> Saldo Awal</td>
              <td></td>
              <td id="head" class="text-right">{{ formatUang(this.saldoAwal)}} </td>
            </tr>
            <tr>
              <td></td>
              <td id="head"> Total Pemasukan</td>
              <td></td>
              <td id="head" class="text-right">{{ formatUang(this.totalPemasukan)}}</td>
            </tr>
            <tr>
              <td></td>
              <td id="head"> Saldo Awal + Pemasukan</td>
              <td></td>
              <td id="head" class="text-right">{{formatUang(this.saldoPemasukan)}}</td>
            </tr>
            <tr>
              <td></td>
              <td id="head"> Pengeluaran</td>
              <td></td>
              <td id="head" class="text-right">{{ formatUang(this.totalPengeluaran)}}</td>
            </tr>
            <tr>
              <td></td>
              <td id="head"> Saldo Akhir</td>
              <td></td>
              <td id="head" class="text-right">{{ formatUang(this.saldoAkhir)}}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <template v-for="e in this.accounts">
              <tr>
                <td>{{e.id}} </td>
                <td id="head"> {{e.namaJenis}} </td>
                <td></td>
                <td class="text-right" id="head"> {{ formatUang(getNilaiJen(e.id)) }} </td>
                <!-- <td id="head" class="text-right">{{ formatUang(this.totalPemasukan)}}</td> -->
                
              </tr>
              <template v-for="x in e.KelompokMAs">
                <tr>
                  <td>{{x.code}}</td>
                  <td id="kelMa">{{x.namaKelMa}} </td>
                  <td class="text-right"> {{ formatUang(getNilaiMa(x.id)) }} </td>
                  <td></td>
                </tr>
              </template>

            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!-- {{getSaldoAwal()}} -->
  </div>
</template>

<script>
import axios from "../API/axios";

import moment from "moment";
import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
export default {
  name: "ReportBulanan",
  components: { DatePicker },
  data() {
    return {
      isLoading: false,
      selectedYear: "",
      saldoAwal: 0,
      totalPemasukan: 0,
      saldoPemasukan: 0,
      pengeluaran: 0,
      saldoAkhir: 0,
      accounts: [],
      amounts: []


    };
  },
  created() {
    this.getHead()
    this.getAccount()
  },
  methods: {
    formatUang(data) {
      let uang = "";
      if(data) {
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
            uang += `,${data[i]}`;
          } else {
            uang += data[i];
          }
        }
      } else {
        return "-"
      }
      return uang

    },

    async getHead() {
      try {
        this.isLoading = true
        let data = await axios({
          method: "GET",
          url: '/report/yearly',
          headers: {
            access_token: localStorage.getItem("access_token")
          },
          params: {
            selectedYear: this.selectedYear
          },
        })
        this.saldoAwal = data.data.saldoAwal
        this.totalPemasukan = data.data.totalPemasukan
        this.saldoPemasukan = data.data.saldoPemasukan
        this.totalPengeluaran = data.data.totalPengeluaran
        this.saldoAkhir = data.data.saldoAkhir
        this.getAccount()
        this.getAmount()
        this.isLoading = false

      } catch(err) {
        this.isLoading = false
        Swal.fire("Maaf", "Periksa Kembali Data Anda", "error");
        console.log(err);
      }
    
    },
    getAccount() {
      axios({
        method: "GET",
        url: '/report/acount',
        headers: {
          access_token: localStorage.getItem("access_token")
        },
        params: {
          selectedYear: this.selectedYear
        },
      }).then(({data}) => {
        this.accounts = data
        // this.getHead()
      }).catch(err => {
        console.log(err);
      })
    },
    getAmount() {
      // let hasil = 0
      axios({
        method: "GET",
        url: '/report/amountyearly',
        headers: {
          access_token: localStorage.getItem("access_token")
        },
        params: {
          selectedYear: this.selectedYear
        },
      }).then(({data}) => {
        console.log(data, "dataa");
        this.amounts = data
      })
    },
    getNilaiMa(id) {
      let hasil = 0
      this.amounts.map(e => {
        if(e.KelompokMAId == id) hasil = e.jumlah
        console.log(hasil);
      })
      return hasil
    },
    getNilaiJen(id) {
      let hasil = 0
      this.amounts.map(e => {
        if(e.JenisTransaksiId == id) hasil += e.jumlah
        console.log(hasil);
      })
      return hasil
    },

    tableHtmlToExcel(
      tableID,
      filename = `Laporan Keuangan Tahunan`   ) {
      // Download Transaksi
      var downloadLink;
      var dataType = "application/vnd.ms-excel";
      var tableSelect = document.getElementById(tableID);
      var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      filename = filename ? filename + ".xls" : "excel_data.xls";

      downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        var blob = new Blob(["\ufeff", tableHTML], {
          type: dataType
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = "data:" + dataType + ", " + tableHTML;

        downloadLink.download = filename;

        downloadLink.click();
      }
    }
  },
};
</script>

<style scoped>
.ReportBulanan {
  width: 85vw;
}
thead tr {
  /* background-color: #1b9aaa; */
  /* color: #0040b8; */
  font-weight: normal;
  font-size: 14px;
}
#uang {
  text-align: right;
}
td,
th {
  font-size: 14px;
  padding: 2px 2px;
}
td {
  text-align: left;
  padding-top: 1px;
}
#head {
  /* text-align-last: left; */
  font-weight: 500;
}
#kelMa{
  padding-left: 20px;
}
#start{
  border-radius: 20px;
  padding: 8px;
}
@media screen and (max-width: 800px) {
  .ReportBulanan {
    padding-left: 10px;
  }
}
</style>