import axios from 'axios';

// export default axios.create({
// 	baseURL: 'http://localhost:3001',
// });
export default axios.create({
	baseURL: 'https://dbklasis2.otnielpangkung.com/',
});
// export default axios.create({
//     baseURL: 'http://dbmkstengah.sikgt.com/'
// })
