<template>
  <div class="app">
    <Navbar />

    <div class="main">
      <Sidebar />
      <div class="mainPage justify-content-center">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Navbar2 from "./components/Navbar2";
export default {
  name: "App",
  components: {
    Navbar,
    Sidebar
  },
  data: () => ({
    //
  })
};
</script>
<style>
#app {
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0;
  color: #2c3e50;
  min-height: 100vh;
}
#titlePage{
  margin-top: 8px;
  font-size: 36px;
  font-weight: 500;
}
#addButton{
  padding: 5px 12px;
  background-color: #002366;
  color: #f5f5f5;
  border-radius: 20px;
  margin: 0;
}
#pencarianTitle{
  margin-top: 12px;
  font-size: 20px;
  margin-left: 8px;
  font-weight: 400;
}
.mainPage {
  /* display: flex; */
  padding: 0 25px;
  width: 92%;
  /* min-width: 100%; */
}
.main {
  display: flex;
}
.page{
  width: 100%;
  padding: 0 15px;
}
#downloadButton{
  background-color: transparent;
  border: none;
}#icon{
  background-color: transparent;
  width: 24px;
}
#iconBoutton {
  background-color: transparent;
  border: none;

}
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}
#pageButton{
  background-color:transparent;
  border: none;
  justify-content: center;
}
#pemasukanTable td, th{
	font-size: 14px;
	padding:  2px 0; 
	padding-left: 5px;
	/* background-color: aqua; */
}
.inRow{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 800px) {
  .mainPage {
    padding: 0;
  }
  * {
    display: none;
  }
}
</style>
