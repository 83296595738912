<template>
  <div class="page">
	<p id="titlePage"> Daftar Transaksi </p>
	<button id="addButton"  @click.prevent="showAdd()">Tambah Transaksi</button>
	<!-- <p id="pencarianTitle">Pencarian</p> -->
	<div class="searchLine">
		<div class="searchKeyword">
			<p id="textSearch">Cari :</p>
			<input id="inputSearch" type="text" v-model="keywordSearch">
		</div>
		<div class="searchDate">
			Tanggal : 
			<input type="date" id="date" v-model="startDate" @change="this.getTransaksi"> - 
			<input type="date" id="date" v-model="endDate" @change="this.getTransaksi">  
		</div>
		<div class="download">
			<button type="button" id="downloadButton" @click.prevent="printCsv()">
				<i class="fa fa-download"></i> Download
			</button>
		</div>
	</div>

	<!-- Page -->
	<div class="pagination mt-1">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
       <p class="mr-1"> {{this.currentPage}} </p>
       <p class="mr-1">/</p>
       <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>

	<!-- {{this.dataTable}} -->
	<div class="inRow">
		<lord-icon
			v-if="this.isLoading"
			src="https://cdn.lordicon.com/dpinvufc.json"
			trigger="loop"
			style="width:500px;height:500px">
		</lord-icon>

	</div>
	<div class="tableLine" v-if="!this.isLoading">
		<div class="tableView">
			<table id="tableTransaksi" class="table-striped border">
				<thead>
					<tr>
						<th>Nama Transaksi</th>
						<th>Tanggal</th>
						<th>Kel. Mata Anggaran</th>
						<th>Mata Anggaran</th>
						<th class="text-right">Debet</th>
						<th class="text-right">Kredit</th>
						<th class="text-center">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="e in dataTable" :key="e.id">
						<td> {{e.namaTransaksi}} </td>
						<td> {{formatTanggal(e.tanggalTransaksi)}} </td>
						<td> {{e.KelompokMA.namaKelMa}} </td>
						<td> {{e.MataAnggaran.namaMataAnggaran}} </td>
						<td class="text-right"> {{ formatUang(formatDK(e, 1))}} </td>
						<td class="text-right"> {{ formatUang(formatDK(e, 2))}} </td>
						<td class="text-center">  
							<a href="" @click.prevent="deleteTransaksi(e.id)"> 
								<img id="icon" src="../assets/trash.png" alt="">
							</a>
							<a href="" @click.prevent="showEdit(e)"> 
								<img id="icon" src="../assets/edit.png" alt="">
							</a>
						</td>
					</tr>
				</tbody>
			</table>
			

		</div>
		<div class="pagination mt-1">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> &lt; </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
       <p class="mr-1"> {{this.currentPage}} </p>
       <p class="mr-1">/</p>
       <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>
	</div>

	<!-- Modal -->
	<div class="modal fade" id="modalTransaksi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="InputTransaksi">
    <div class="col">
      <div class="form container-fluid">
        <form @submit.prevent="isEdit ? editTransaksi() : inputTransaksi()">
          <div class="form-row">
            <div class="col-sm">
              <label class="my-1 mr-2" for="Transaksi">Nama Transaksi</label>
              <div class="input-group">
                <input
                  v-model="namaTransaksi"
                  type="text"
                  id="User_ID"
                  class="form-control"
                  placeholder="Isi Nama Transaksi..."
                  required
                />
                <input
                  type="date"
                  v-model="tanggalTransaksi"
                  id="User_ID"
                  class="form-control"
                  required
                />
              </div>
            </div>
            <div class="col-sm">
              <label class="my-1 mr-2" for="Username">Jenis Transaksi</label>
              <div class="input-group">
                <select class="form-control" @change="getKelMa()" v-model="JenisTransaksiId" required>
                  <option selected disabled>Pilih Jenis Transaksi</option>
                  <option
                    v-for="jenis in this.jenisTransaksiList"
                    :key="jenis.id"
                    :value="jenis.id"
                  >{{jenis.namaJenis}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-sm">
              <label for="Harga" class="my-1 mr-2">Nominal</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="Rp">Rp</span>
                </div>
                <input type="number" v-model="jumlah" class="form-control" required />
              </div>
              <label class="my-1 mr-2" for="kelompokMA">Kelompok Mata Anggaran</label>
              <div class="input-group">
                <select class="form-control" @change="this.getMa" v-model="KelompokMAId" required>
                  <option
                    v-for="kelompok in this.kelMAnggarans"
                    :key="kelompok.id"
                    :value="kelompok.id"
                  >{{kelompok.namaKelMa}}</option>
                </select>
              </div>
              <label class="my-1 mr-2" for="MataAnggaran">Mata Anggaran</label>
              <div class="input-group">
                <select class="form-control" v-model="MataAnggaranId" required>
                  <option
                    v-for="mataanggaran in this.mAnggarans"
                    :key="mataanggaran.id"
                    :value="mataanggaran.id"
                  >{{mataanggaran.namaMataAnggaran}}</option>
                </select>
              </div>
            </div>
            <div class="col-sm">
              <label class="my-1 mr-2" for="Keterangan">Keterangan:</label>
              <div class="input-group">
                <textarea class="form-control" v-model="keterangan" rows="7"></textarea>
              </div>
              <div class="d-flex justify-content-end my-2">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
import axios from '../API/axios';
import moment from 'moment';
import Swal from "sweetalert2";
import csvDownload from 'json-to-csv-export'

export default {
	name: "AllTransaksi",
	data() {
			return {
				isLoading: false,
				namaTransaksi: "",
				keterangan: "",
				tanggalTransaksi: "",
				kelMAnggarans: [],
				mAnggarans: [],
				MataAnggaranId: 0,
				JenisTransaksiId: 0,
				jumlah: 0,
				KelompokMAId: 0,
				dataTable: "",
				currentPage: 1,
				perPage: 20,
				totalPage : 1,
				dataLeng: 0,
				keywordSearch: "",
				startDate: '',
				endDate: '',
				isEdit: false,
				showModal: true,
				id: 0,
				filter: null,
				rerenderTransaksi: false,
				dataPrint : []
			};
		},
	created() {
		this.getTransaksi()
		this.$store.dispatch("fetchJenisTransaksi");
	},
	computed: {
		jenisTransaksiList() {
		return this.$store.state.jenisTransaksi;
		}
	},
	methods : {

		showAdd() {
			this.isEdit = false
			$('#modalTransaksi').modal('show')
		},
		showEdit(e) {
			this.namaTransaksi = e.namaTransaksi
			this.keterangan = e.keterangan
			this.jumlah = e.jumlah
			this.KelompokMAId = e.KelompokMAId
			this.MataAnggaranId = e.MataAnggaranId
			this.JenisTransaksiId = e.JenisTransaksiId
			this.tanggalTransaksi = e.tanggalTransaksi
			this.id  = e.id
			this.isEdit = true
			$('#modalTransaksi').modal('show')

		},
		formatTanggal(data) {
				let hasil = '';
				var time = moment(data).format('DD-MM-YYYY');
				return time;
		},
		async printCsv () {

			try {
				this.isLoading = true
				let data = await axios({
					url: "transaksi/print",
					method: "GET",
					headers: {
						access_token: localStorage.getItem("access_token")
					},
					params: {
						startDate: this.startDate,
						endDate: this.endDate,
					}
				})
				this.dataPrint= data.data.data
                
                const dataToConvert = {
                data: this.dataPrint,
                filename: `Transaksi dari Tanggal ${this.startDate} sampai ${this.endDate}`,
                delimiter: ',',
                }
                csvDownload(dataToConvert)
                // const to = setTimeout(() => {
                //     this.isLoading = false
                //     clearTimeout(to)
                // }, 3000)
                this.isLoading = false

			} catch(err) {
                this.isLoading = false
				console.log(err);
			}
		},
		editTransaksi() {
		let payload = {
			namaTransaksi: this.namaTransaksi,
			keterangan: this.keterangan,
			tanggalTransaksi: this.tanggalTransaksi,
			MataAnggaranId: +this.MataAnggaranId,
			jumlah: +this.jumlah,
			JenisTransaksiId: +this.JenisTransaksiId,
			KelompokMAId: +this.KelompokMAId
		};

		return axios.put(`/transaksi/${this.id}`, payload, {
			headers: {
				access_token: localStorage.getItem("access_token")
			}
			})
			.then(({ data }) => {
			this.namaTransaksi= "",
			this.keterangan= "",
			this.tanggalTransaksi= "",
			this.MataAnggaranId= 0,
			this.jumlah= 0,
			this.id= 0,
			this.JenisTransaksiId= 0,
			this.KelompokMAId= 0,
			this.getTransaksi()
			Swal.fire({
				title: "Berhasil",
				text: "Data Berhasil Ditambahkan",
				icon: "success",
				confirmButtonText: "Ok"
			});
			this.$router.push("/report/transaksi");
			$('#modalTransaksi').modal('hide')
			})
			.catch(err => {
			Swal.fire("Maaf", "Periksa Kembali Data Anda", "error");
			console.log(err);
			});
		},
		inputTransaksi() {
		let payload = {
			namaTransaksi: this.namaTransaksi,
			keterangan: this.keterangan,
			tanggalTransaksi: this.tanggalTransaksi,
			MataAnggaranId: +this.MataAnggaranId,
			jumlah: +this.jumlah,
			JenisTransaksiId: +this.JenisTransaksiId,
			KelompokMAId: +this.KelompokMAId
		};

		return axios
			.post("/transaksi", payload, {
			headers: {
				access_token: localStorage.getItem("access_token")
			}
			})
			.then(({ data }) => {
			this.namaTransaksi= "",
			this.keterangan= "",
			this.tanggalTransaksi= "",
			this.MataAnggaranId= 0,
			this.jumlah= 0,
			this.JenisTransaksiId= 0,
			this.KelompokMAId= 0,
			this.getTransaksi()
			Swal.fire({
				title: "Berhasil",
				text: "Data Berhasil Ditambahkan",
				icon: "success",
				confirmButtonText: "Ok"
			});
			this.$router.push("/report/transaksi");
			$('#modalTransaksi').modal('hide')
			})
			.catch(err => {
			Swal.fire("Maaf", "Periksa Kembali Data Anda", "error");
			console.log(err);
			});
		},
		async getTransaksi() {
			try {
				this.isLoading = true
				let data = await axios({
						method: 'GET',
						url: '/transaksi/',
						headers: {
							access_token: localStorage.getItem("access_token")
						},
						params: {
							startDate: this.startDate,
							endDate: this.endDate,
							page: this.currentPage,
							limit: this.perPage,
						},
				})
				 
				this.dataTable = data.data.data2.result
				let totalPage = Math.ceil(data.data.data2.dataLength / this.perPage)
				this.totalPage = totalPage

				this.isLoading = false

			} catch(err) {
				this.isLoading = false
				console.log(err);
			}
	
		},
		deleteTransaksi(id) {
			return axios.delete(`/transaksi/${id}`, {
				headers: {
						access_token: localStorage.getItem("access_token")
					},
			}).then(data => {
				this.getTransaksi()
				Swal.fire({
					title: "Berhasil",
					text: "Data Berhasil Dihapus",
					icon: "success",
				})
			})
		},
		formatDK(data, x) {
			let hasil = "-"
			if (data.JenisTransaksiId == x) {
				hasil = data.jumlah
			}
			return hasil
		},
		formatUang(data) {
			let uang = "";
			data = data.toString();
			for (let i = 0; i < data.length; i++) {
				if ((data.length - i) % 3 == 0 && i !== 0) {
				uang += `.${data[i]}`;
				} else {
				uang += data[i];
				}
			}
			return uang;
		},
		getKelMa() {
			axios({
				method: "Get",
				url: '/transaksi/kelma',
							headers: {
								access_token: localStorage.getItem("access_token")
							},
				params: {
								JenisTransaksiId: this.JenisTransaksiId,
							},

			}).then(({data}) => {
				this.kelMAnggarans = data
			})
		},
		getMa() {
			axios({
				method: "Get",
				url: '/transaksi/ma',
							headers: {
								access_token: localStorage.getItem("access_token")
							},
				params: {
								KelompokMAId: this.KelompokMAId,
							},

			}).then(({data}) => {
				this.mAnggarans = data
			})
		},
		addPage() {
            this.currentPage = this.currentPage + 1
			this.getTransaksi()
        },
        minPage() {
            this.currentPage = this.currentPage - 1
			this.getTransaksi()

        }


	}
}
</script>

<style scoped>

p{
	margin: 0;
}
.searchLine{
	margin-top: 8px;
	display: flex;
	/* background-color: rgb(238, 27, 27); */
	/* margin: 0 20px; */
	align-items: center;
	width: 100%;
	justify-content: space-between;
}
.searchKeyword{
	display: flex;
}
.searchDate{
	display: flex;
}
#inputSearch{
	border: 1px solid #c1c1c7;
	border-radius: 20px;
	padding: 3px 15px
}
#date{
	border: 1px solid #c1c1c7;
	padding: 3px 15px;
	margin: 0 12px;
	border-radius: 10px;
}
#textSearch{
	margin: 0;
	margin-right: 3px;
}
.tableLine{
	margin-top: 8px;
	max-width: 100%;
}

table{
	margin-top: 10px;
	min-width: 1800px;
}
#tableTransaksi td, th{
	font-size: 14px;
	padding:  2px 0; 
	padding-left: 5px;
	/* background-color: aqua; */
}

.tableView {
	overflow-x: scroll;
	overflow-y: scroll;
	min-width: 100%
	
}
</style>